import React, { useState } from "react";
import membership_img from "../Images/membership_img.jpg";
import MembershipForm from "../Images/20230120 PRWA Membership forms .pdf";

function Membership() {
  const [hovering, setHovering] = useState(false);

  let blackoutFun = () => {
    setHovering(true);
  };

  let blackoutCloseFun = () => {
    setHovering(false);
  };

  return (
    <>
      <div className='text-center my-8 mx-4 md:my-16' id='member'>
        <h1 className='my-3 text-[2rem] font-semibold text-[#1E1E1E]'>
          Membership Form
        </h1>
        <div
          className='rounded-xl h-[20rem] w-[15rem] lg:h-[25rem] lg:w-[20rem] mx-auto mt-8 '
          onMouseEnter={() => blackoutFun()}
          onMouseLeave={() => blackoutCloseFun()}
        >
          <a href={MembershipForm} rel='noreferrer' target='_blank'>
            <div
              className={`rounded-xl h-[20rem] mx-auto w-[15rem] lg:h-[25rem] lg:w-[20rem]  bg-[#0000006d] absolute text-white text-center items-center justify-center text-[2rem] duration-1000 ${
                hovering === true ? "fileblackoutClass " : "hidden"
              }`}
            >
              Download
            </div>
            <img
              src={membership_img}
              alt={membership_img}
              className='rounded-xl w-[15rem] h-[20rem] lg:h-[25rem] lg:w-[20rem] mx-auto border-2 border-[#dfdfdf]'
              style={{ boxShadow: "-10px 20px 25px -5px rgba(0, 0, 0, 0.2)" }}
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default Membership;
