import React from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import DussehraContent from "./DussehraContent";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

function Dussehra() {
  return (
    <>
      <div className=''>
        <div className=' text-center'>
          <h1 className='text-[1.5rem] mt-4 font-semibold'>Dussehra</h1>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={20}
            slidesPerView={3}
            // navigation
            autoplay={true}
            pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log("slide change")}
            className='mt-[2rem] w-[75vw] mx-auto my-auto'
          >
            {DussehraContent.map((elm) => {
              return (
                <SwiperSlide className='my-auto' key={elm.id}>
                  <div className=''>
                    <img
                      src={elm.src}
                      className='h-auto mb-8 rounded-md shadow-md shadow-slate-700'
                      alt={elm.src}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Dussehra;
