import React, { useState } from "react";
import logo_img from "../Images/logo-parikrama.png";
import { HiOutlineBars3BottomRight } from "react-icons/hi2";
import { Link } from "react-scroll";

function Navbar() {
  let [toggle, setToggle] = useState(false);

  return (
    <>
      <div className='p-2' id='navbar'>
        <div className='grid grid-cols-2 md:grid-cols-4 justify-between'>
          <div className=''>
            <img src={logo_img} alt={logo_img} className=' w-[6rem] md:ml-4' />
          </div>
          <div className='relative md:hidden'>
            <HiOutlineBars3BottomRight
              className='text-[2.5rem] absolute right-2 top-2 hover:text-white hover:bg-[#1E1E1E] p-2 rounded-full'
              onClick={() => {
                toggle === false ? setToggle(true) : setToggle(false);
              }}
            />
          </div>
          <div className='hidden md:inline col-span-3 text-center  text-[14px] my-auto'>
            <ul className=' font-semibold grid grid-cols-8 justify-evenly'>
              <li className='my-1 ls w-max'>
                <Link
                  to='header'
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className=''
                >
                  Home
                </Link>
              </li>
              <li className='my-1 ls w-max'>
                <Link
                  to='about'
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className=''
                >
                  About
                </Link>
              </li>
              <li className='my-1 ls w-max'>
                <Link
                  to='gallery'
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className=''
                >
                  Gallery
                </Link>
              </li>
              <li className='my-1 ls w-max'>
                <Link
                  to='laws'
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className=''
                >
                  By Laws
                </Link>
              </li>
              <li className='my-1 ls w-max'>
                <Link
                  to='governing'
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className=''
                >
                  Governing
                </Link>
              </li>
              <li className='my-1 ls w-max'>
                <Link
                  to='member'
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className=''
                >
                  Membership
                </Link>
              </li>
              <li className='my-1 ls w-max'>
                <Link
                  to='apartment'
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className=''
                >
                  Apartments
                </Link>
              </li>
              <li className='my-1 ls w-max'>
                <Link
                  to='contact'
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className=''
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`md:hidden opacity-0 duration-1000 py-4 ${
            toggle === true ? "inline opacity-100" : "hidden"
          }`}
        >
          <ul className=' font-semibold'>
            <li className='my-1 lsm'>
              <Link
                to='navbar'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className=''
              >
                Home
              </Link>
            </li>
            <li className='my-1 lsm'>
              <Link
                to='about'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className=''
              >
                About
              </Link>
            </li>
            <li className='my-1 lsm'>
              <Link
                to='gallery'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className=''
              >
                Gallery
              </Link>
            </li>
            <li className='my-1 lsm'>
              <Link
                to='laws'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className=''
              >
                By Laws
              </Link>
            </li>
            <li className='my-1 lsm'>
              <Link
                to='governing'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className=''
              >
                Governing Body
              </Link>
            </li>
            <li className='my-1 lsm'>
              <Link
                to='member'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className=''
              >
                Membership
              </Link>
            </li>
            <li className='my-1 lsm'>
              <Link
                to='apartment'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className=''
              >
                Apartments
              </Link>
            </li>
            <li className='my-1 lsm'>
              <Link
                to='contact'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className=''
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar;
