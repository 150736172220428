const GeneralContent = [
  {
    id: 1,
    src: "./Images/SubImg/g1.png",
  },
  {
    id: 2,
    src: "./Images/SubImg/g2.png",
  },
  {
    id: 3,
    src: "./Images/SubImg/g3.png",
  },
  {
    id: 4,
    src: "./Images/SubImg/g4.png",
  },
  {
    id: 5,
    src: "./Images/SubImg/g5.png",
  },
  {
    id: 6,
    src: "./Images/SubImg/g6.png",
  },
  {
    id: 7,
    src: "./Images/SubImg/g7.png",
  },
  {
    id: 8,
    src: "./Images/SubImg/g8.png",
  },
  {
    id: 9,
    src: "./Images/SubImg/g9.png",
  },
  {
    id: 10,
    src: "./Images/SubImg/g10.png",
  },
  {
    id: 11,
    src: "./Images/SubImg/g11.png",
  },
  {
    id: 12,
    src: "./Images/SubImg/g12.png",
  },
  {
    id: 13,
    src: "./Images/SubImg/g13.png",
  },
  {
    id: 14,
    src: "./Images/SubImg/g14.png",
  },
  {
    id: 15,
    src: "./Images/SubImg/g15.png",
  },
  {
    id: 16,
    src: "./Images/SubImg/g16.png",
  },
  {
    id: 17,
    src: "./Images/SubImg/g17.png",
  },
  {
    id: 18,
    src: "./Images/SubImg/g18.png",
  },
];

export default GeneralContent;
