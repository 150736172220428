import React from "react";
import GoverningData from "./GoverningData";

function Governing() {
  return (
    <>
      <div className='text-center my-12 mx-4 md:my-20' id='governing'>
        <h1 className='my-3 text-[2rem] font-semibold text-[#1E1E1E]'>
          Governing Body
        </h1>
        <p className=' font-normal'>
          Election Were held on 10th July 2022 & following members were elected
          :-
        </p>
        <div className=' grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 justify-evenly mt-10 text-center gap-y-10 lg:w-[85vw] lg:mx-auto'>
          {GoverningData.map((elm) => {
            const { id, name, src, designation } = elm;
            return (
              <div
                className='hover:scale-110 duration-500 shadow-slate-700'
                key={id}
              >
                <img
                  src={src}
                  alt={src}
                  className='mx-auto h-[5rem] w-[5rem] rounded-full shadow-md shadow-slate-700'
                />
                <p className=' font-bold mt-2'>{name}</p>
                <p className=''>{designation}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Governing;
