import "./App.css";
import About from "./Components/About";
import Header from "./Components/Header";
import Gallery from "./Components/Gallery";
import Laws from "./Components/Laws";
import Membership from "./Components/Membership";
import Apartment from "./Components/Apartment";
import Governing from "./Components/Governing";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import { Link } from "react-scroll";
import Navbar from "./Components/Navbar";
import Contact from "./Components/Contact";

function App() {
  return (
    <>
      <Navbar />
      <Header />
      <About />
      <Gallery />
      <Laws />
      <Governing />
      <Membership />
      <Apartment />
      <Contact />
      <Link
        to='navbar'
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
        className=' fixed bottom-[15rem] right-4 text-[2rem] cursor-pointer z-20 '
      >
        <BsFillArrowUpCircleFill />
      </Link>
    </>
  );
}

export default App;
