import React from "react";
import hero_bg from "../Images/hero_bg.jpeg";
import hero_bg2 from "../Images/bg_mobile.jpeg";

function Header() {
  return (
    <>
      <div id="header">
        <div
          className="h-[80vh] w-[100vw] bg-cover text-white relative sm:hidden"
          style={{ backgroundImage: `url(${hero_bg2})` }}
        >
          <div
            className="h-[80vh] w-[100vw]"
            style={{
              background:
                "linear-gradient(11.62deg, rgba(1,3,67,1) 52.91%, rgba(0,0,0,0.258312324929972) 53.13%)",
            }}
          ></div>

          <div className="absolute bottom-[3rem] text-center w-[100vw]">
            <p className="text-[15px]">
              PARIKRAMA RESIDENTS WELFARE ASSOCIATION
            </p>
            <h1 className="my-2 text-[2rem] uppercase tracking-wider">
              Parikrama
            </h1>
            <p className="">Circle Of Life</p>
            <p className="my-2">Sector 20, PANCHKULA</p>
            <p className="">Our Goodness is Sum of our Residents’ Goodness</p>
          </div>
        </div>

        <div
          className="h-[65vh] lg:h-[70vh] w-[100vw] bg-cover text-white relative hidden sm:block"
          style={{ backgroundImage: `url(${hero_bg})` }}
        >
          <div
            className="h-[65vh] lg:h-[70vh] w-[100vw]"
            style={{
              background:
                "linear-gradient(63.48deg, rgba(1,3,67,1) 50.38%, rgba(0,0,0,0) 45.38%)",
            }}
          ></div>

          <div className="absolute top-[9rem] left-[-0.8rem] text-center w-[50vw]">
            <p className="text-[18px]">
              PARIKRAMA RESIDENTS <br className="lg:hidden" />
              WELFARE ASSOCIATION
            </p>
            <h1 className="my-2 text-[2.8rem] uppercase tracking-wider">
              Parikrama
            </h1>
            <p className="text-[18px]">Circle Of Life</p>
            <p className="my-2 lg:my-4 text-[18px]">Sector 20, PANCHKULA</p>
            <p className="text-[18px]">
              Our Goodness is Sum of our <br className="lg:hidden" />
              Residents’ Goodness
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
