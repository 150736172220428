const RepublicDay = [
  {
    id: 1,
    src: "./Images/SubImg/r01.png",
  },
  {
    id: 2,
    src: "./Images/SubImg/r02.png",
  },
  {
    id: 3,
    src: "./Images/SubImg/r03.png",
  },
  {
    id: 4,
    src: "./Images/SubImg/r04.png",
  },
  {
    id: 5,
    src: "./Images/SubImg/r01.png",
  },
  {
    id: 6,
    src: "./Images/SubImg/r02.png",
  },
  {
    id: 7,
    src: "./Images/SubImg/r03.png",
  },
  {
    id: 8,
    src: "./Images/SubImg/r04.png",
  },
];

export default RepublicDay;
