import React from "react";

function Contact() {
  return (
    <>
      <div
        className=' bg-[#010343] text-white py-4 px-4 grid grid-cols-1 md:grid-cols-2 justify-between text-center'
        id='contact'
      >
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.9508897230025!2d76.85413921512982!3d30.663511381661344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f9493bd353ad3%3A0xa3ff52697455ad6!2sParikarma%2C%20Suncity%20Parikrama%2C%20Sector%2020%2C%20Panchkula%2C%20Haryana%20134116!5e0!3m2!1sen!2sin!4v1676289722137!5m2!1sen!2sin'
          width='600'
          title='Parikrama_Society'
          height='450'
          style={{ border: "0" }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
          className='w-[80vw] mx-auto h-[30vh] rounded-lg my-6 md:w-[45vw] md:h-[30vh] lg:w-[40vw]'
        ></iframe>

        <div className='grid grid-cols-2 justify-between my-auto'>
          <div className=''>
            <h1 className=' font-semibold mb-1 text-[0.9rem]'>
              Emergency Contact 24x7
            </h1>
            <div className='text-[0.8rem]'>
              <p className=''>Mr. Sunil</p>
              <p className=''>9034040987</p>
              <p className=''>gb@prwapkl.com</p>
            </div>
          </div>

          <div className=''>
            <h1 className='font-semibold mb-1 text-[0.9rem]'>
              For Function/Event
            </h1>
            <div className='text-[0.8rem]'>
              <p className=''>Mr. Brijmohan Sharma</p>
              <p className=''>9034990987</p>
              <p className=''>brij.sharmaprwapkl@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
