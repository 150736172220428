const AerialContent = [
  {
    id: 1,
    src: "./Images/SubImg/a01.png",
  },
  {
    id: 2,
    src: "./Images/SubImg/a03.png",
  },
  {
    id: 3,
    src: "./Images/SubImg/a04.png",
  },
  {
    id: 4,
    src: "./Images/SubImg/a05.png",
  },
  {
    id: 5,
    src: "./Images/SubImg/a01.png",
  },
  {
    id: 6,
    src: "./Images/SubImg/a03.png",
  },
  {
    id: 7,
    src: "./Images/SubImg/a04.png",
  },
  {
    id: 8,
    src: "./Images/SubImg/a05.png",
  },
];

export default AerialContent;
