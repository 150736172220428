const GoverningData = [
  {
    id: 1,
    name: "Mr. Udit Mittal",
    designation: "President",
    src: "./Images/p1.jpg",
  },
  {
    id: 2,
    name: "Mr. Mukesh Jindal",
    designation: "Vice President",
    src: "./Images/p2.jpg",
  },
  {
    id: 3,
    name: "Ms. Rekha Gupta",
    designation: "Gen. Secretary",
    src: "./Images/p3.jpg",
  },
  {
    id: 4,
    name: "Ms. Ruby Gupta",
    designation: "Joint Secretary",
    src: "./Images/p4.jpg",
  },
  {
    id: 5,
    name: "Mr. Vijay Singhal",
    designation: "Treasurer",
    src: "./Images/p5.jpg",
  },
  {
    id: 6,
    name: "Mr. HL Gupta",
    designation: "Executive Member",
    src: "./Images/p6.jpg",
  },
  {
    id: 7,
    name: "Mr. VM Marya",
    designation: "Executive Member",
    src: "./Images/p7.jpg",
  },
  {
    id: 8,
    name: "Ms. Jaswinder Kaur",
    designation: "Executive Member",
    src: "./Images/p8.jpg",
  },
  {
    id: 9,
    name: "Ms. Veena Tondon",
    designation: "Executive Member",
    src: "./Images/p9.jpg",
  },
  {
    id: 10,
    name: "Mr. Lalit Chopra",
    designation: "Executive Member",
    src: "./Images/p10.jpg",
  },
];

export default GoverningData;
