import React from "react";
import apartment_img from "../Images/apartmentMin.jpg";

function Apartment() {
  return (
    <>
      <div className=' my-12 mx-4 md:my-20' id='apartment'>
        <h1 className=' text-center my-3 text-[2rem] font-semibold text-[#1E1E1E]'>
          Apartments
        </h1>
        <div className=' grid grid-cols-1 sm:grid-cols-2 justify-evenly lg:mt-[3rem] lg:w-[85vw] lg:mx-auto'>
          <div className='rounded-xl w-3/4 mx-auto sm:my-auto xl:w-[80%] sm:order-2'>
            <img
              src={apartment_img}
              alt={apartment_img}
              className='rounded-xl'
            />
          </div>
          <ul className=' my-6 list-disc mx-8 lg:my-auto sm:order-1 lg:w-[90%] lg:mx-auto'>
            <li className=''>Duplex :- 10</li>
            <li className=''>Penthouse :- 10</li>
            <li className=''>Five BHK + Servant Quarter:- 72 </li>
            <li className=''>Four BHK + Servant Quarter:- 64 </li>
            <li className=''>Three BHK + Servant Quarter(2150 sqft):- 260</li>
            <li className=''>Three BHK + Servant Quarter (1850 sqft) :- 697</li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Apartment;
