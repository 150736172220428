const KarwaContent = [
  {
    id: 1,
    src: "./Images/SubImg/k01.png",
  },
  {
    id: 2,
    src: "./Images/SubImg/k02.png",
  },
  {
    id: 3,
    src: "./Images/SubImg/k03.png",
  },
  {
    id: 4,
    src: "./Images/SubImg/k01.png",
  },
  {
    id: 5,
    src: "./Images/SubImg/k02.png",
  },
  {
    id: 6,
    src: "./Images/SubImg/k03.png",
  },
];

export default KarwaContent;
