const DiwaliContent = [
  {
    id: 1,
    src: "./Images/SubImg/d01.png",
  },
  {
    id: 2,
    src: "./Images/SubImg/d02.png",
  },
  {
    id: 3,
    src: "./Images/SubImg/d03.png",
  },
  {
    id: 4,
    src: "./Images/SubImg/d01.png",
  },
  {
    id: 5,
    src: "./Images/SubImg/d02.png",
  },
  {
    id: 6,
    src: "./Images/SubImg/d03.png",
  },
];

export default DiwaliContent;
