const LohriContent = [
  {
    id: 1,
    src: "./Images/SubImg/lo1.png",
  },
  {
    id: 2,
    src: "./Images/SubImg/l02.png",
  },
  {
    id: 3,
    src: "./Images/SubImg/l03.png",
  },
  {
    id: 4,
    src: "./Images/SubImg/lo1.png",
  },
  {
    id: 5,
    src: "./Images/SubImg/l02.png",
  },
  {
    id: 6,
    src: "./Images/SubImg/l03.png",
  },
];

export default LohriContent;
