const NavratreContent = [
  {
    id: 1,
    src: "./Images/SubImg/n01.png",
  },
  {
    id: 2,
    src: "./Images/SubImg/n02.png",
  },
  {
    id: 3,
    src: "./Images/SubImg/n03.png",
  },
  {
    id: 4,
    src: "./Images/SubImg/n01.png",
  },
  {
    id: 5,
    src: "./Images/SubImg/n02.png",
  },
  {
    id: 6,
    src: "./Images/SubImg/n03.png",
  },
];

export default NavratreContent;
