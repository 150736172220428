import React from "react";
import about_img from "../Images/about_img.png";

function About() {
  return (
    <>
      <div className='text-center my-8 mx-4 md:w-[85vw] md:mx-auto' id='about'>
        <h1 className='my-3 text-[2rem] font-semibold text-[#1E1E1E]'>
          About Us
        </h1>
        <div className=' grid grid-cols-1 justify-evenly md:grid-cols-2'>
          <div className='md:my-auto'>
            <img
              src={about_img}
              alt={about_img}
              className='w-3/4 mx-auto lg:mt-[-2rem] xl:mt-[-1rem]'
            />
          </div>
          <p className=' my-6 w-[90vw] md:w-auto mx-auto lg:mt-9'>
            Parikrama Group Housing Colony (PHGC) Situated in Sector 20
            Panchkula Haryana. “Parikrama Group Housing Colony was constructed
            from 2010 onwards. Occupation certificates were granted in year 2016
            & 2017. Later in year 2018 was issued by DTCP. A review application
            was filed by some & flat owners. The PGHC has following components:-
            1.1113 main appartments in six types of configrations in 24 towers.
            2.208 Econumically weaker section flats (EWS). 3.Basement parking
            4.Two Community Centres. 5.One swimming pool. 6.19 Shops. 7.Lawns &
            Fountain. 8.Reception lobby in each tower
          </p>
        </div>
      </div>
    </>
  );
}

export default About;
