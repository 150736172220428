const DussehraContent = [
  {
    id: 1,
    src: "./Images/SubImg/du01.png",
  },
  {
    id: 2,
    src: "./Images/SubImg/du02.png",
  },
  {
    id: 3,
    src: "./Images/SubImg/du03.png",
  },
  {
    id: 4,
    src: "./Images/SubImg/du01.png",
  },
  {
    id: 5,
    src: "./Images/SubImg/du02.png",
  },
  {
    id: 6,
    src: "./Images/SubImg/du03.png",
  },
];

export default DussehraContent;
