import React, { useState } from "react";
import aerialView from "../Images/areialView.jpg";
import diwali_img from "../Images/diwali_img.jpg";
import dussera_img from "../Images/du03.png";
import karwa_img from "../Images/k03.png";
import lohri_img from "../Images/lohri_img.jpg";
import republic_img from "../Images/r02.png";
import navratre_img from "../Images/navratre_img.jpg";
import general_img from "../Images/g18.png";
import AerialView from "./AerialView";
import { RxCross2 } from "react-icons/rx";
import Navratre from "./Navratre";
import Diwali from "./Diwali";
import Dussehra from "./Dussehra";
import Lohri from "./Lohri";
import Republic from "./Republic";
import Karwa from "./Karwa";
import General from "./General";

function Gallery() {
  const [hovering, setHovering] = useState(false);
  const [hovering1, setHovering1] = useState(false);
  const [hovering2, setHovering2] = useState(false);
  const [hovering3, setHovering3] = useState(false);
  const [hovering4, setHovering4] = useState(false);
  const [hovering5, setHovering5] = useState(false);
  const [hovering6, setHovering6] = useState(false);
  const [hovering7, setHovering7] = useState(false);

  const [Box1, setBox1] = useState(true);
  const [Box2, setBox2] = useState(true);
  const [Box3, setBox3] = useState(true);
  const [Box4, setBox4] = useState(true);
  const [Box5, setBox5] = useState(true);
  const [Box6, setBox6] = useState(true);
  const [Box7, setBox7] = useState(true);
  const [Box8, setBox8] = useState(true);

  let blackoutFun = () => {
    setHovering(true);
  };

  let blackoutCloseFun = () => {
    setHovering(false);
  };
  let blackout1Fun = () => {
    setHovering1(true);
  };

  let blackoutClose1Fun = () => {
    setHovering1(false);
  };
  let blackout2Fun = () => {
    setHovering2(true);
  };

  let blackoutClose2Fun = () => {
    setHovering2(false);
  };
  let blackout3Fun = () => {
    setHovering3(true);
  };

  let blackoutClose3Fun = () => {
    setHovering3(false);
  };
  let blackout4Fun = () => {
    setHovering4(true);
  };

  let blackoutClose4Fun = () => {
    setHovering4(false);
  };
  let blackout5Fun = () => {
    setHovering5(true);
  };

  let blackoutClose5Fun = () => {
    setHovering5(false);
  };
  let blackout6Fun = () => {
    setHovering6(true);
  };

  let blackoutClose6Fun = () => {
    setHovering6(false);
  };
  let blackout7Fun = () => {
    setHovering7(true);
  };

  let blackoutClose7Fun = () => {
    setHovering7(false);
  };

  return (
    <>
      <div className='text-center my-8 mx-4 md:my-16 relative' id='gallery'>
        <h1 className='my-3 text-[2rem] font-semibold text-[#1E1E1E]'>
          Gallery
        </h1>

        <div className='grid grid-cols-1 justify-evenly sm:grid-cols-2 md:mt-14 lg:grid-cols-3 lg:w-[85vw] lg:mx-auto'>
          {/*  */}
          <div
            className={`animatedClass absolute top-[4rem] sm:top-[6rem] left-[0rem] sm:left-4 lg:left-8 lg:top-[8rem] z-40  w-[90vw]  bg-[white] shadow-lg shadow-slate-600 pb-4 rounded-xl ${
              Box1 === true ? "hidden" : "inline-block"
            }`}
          >
            <RxCross2
              className='absolute top-4 right-4 text-[1.5rem] z-50 '
              onClick={() => setBox1(true)}
            />
            <General />
          </div>
          {/*  */}

          {/*  */}
          <div
            className={`animatedClass absolute top-[16rem] sm:top-[6rem] left-[0rem] sm:left-4 lg:left-8 lg:top-[8rem] z-40  w-[90vw] bg-[white] shadow-lg shadow-slate-600 pb-4 rounded-xl ${
              Box2 === true ? "hidden" : "inline-block"
            }`}
          >
            <RxCross2
              className='absolute top-4 right-4 text-[1.5rem] z-50'
              onClick={() => setBox2(true)}
            />
            <AerialView />
          </div>
          {/*  */}

          {/*  */}
          <div
            className={`animatedClass absolute top-[26rem] sm:top-[14rem] left-[0rem] sm:left-4 lg:left-8 lg:top-[8rem] z-40  w-[90vw] bg-[white] shadow-lg shadow-slate-600 pb-4 rounded-xl ${
              Box3 === true ? "hidden" : "inline-block"
            }`}
          >
            <RxCross2
              className='absolute top-4 right-4 text-[1.5rem] z-50'
              onClick={() => setBox3(true)}
            />
            <Diwali />
          </div>
          {/*  */}
          {/*  */}
          <div
            className={`animatedClass absolute top-[38rem] sm:top-[14rem] left-[0rem] sm:left-4 lg:left-8 lg:top-[15rem] z-40  w-[90vw] bg-[white] shadow-lg shadow-slate-600 pb-4 rounded-xl ${
              Box4 === true ? "hidden" : "inline-block"
            }`}
          >
            <RxCross2
              className='absolute top-4 right-4 text-[1.5rem] z-50'
              onClick={() => setBox4(true)}
            />
            <Dussehra />
          </div>
          {/*  */}
          {/*  */}
          <div
            className={`animatedClass absolute top-[48rem] sm:top-[24rem] left-[0rem] sm:left-4 lg:left-8 lg:top-[15rem] z-40  w-[90vw] bg-[white] shadow-lg shadow-slate-600 pb-4 rounded-xl ${
              Box5 === true ? "hidden" : "inline-block"
            }`}
          >
            <RxCross2
              className='absolute top-4 right-4 text-[1.5rem] z-50'
              onClick={() => setBox5(true)}
            />
            <Karwa />
          </div>
          {/*  */}
          {/*  */}
          <div
            className={`animatedClass absolute top-[60rem] sm:top-[24rem] left-[0rem] sm:left-4 lg:left-8 lg:top-[15rem] z-40  w-[90vw] bg-[white] shadow-lg shadow-slate-600 pb-4 rounded-xl ${
              Box6 === true ? "hidden" : "inline-block"
            }`}
          >
            <RxCross2
              className='absolute top-4 right-4 text-[1.5rem] z-50'
              onClick={() => setBox6(true)}
            />
            <Lohri />
          </div>
          {/*  */}
          {/*  */}
          <div
            className={`animatedClass absolute top-[70rem] sm:top-[36rem] left-[0rem] sm:left-4 lg:left-8 lg:top-[18rem] z-40  w-[90vw] bg-[white] shadow-lg shadow-slate-600 pb-4 rounded-xl ${
              Box7 === true ? "hidden" : "inline-block"
            }`}
          >
            <RxCross2
              className='absolute top-4 right-4 text-[1.5rem] z-50'
              onClick={() => setBox7(true)}
            />
            <Republic />
          </div>
          {/*  */}
          {/*  */}
          <div
            className={`animatedClass absolute top-[82rem] sm:top-[36rem] left-[0rem] sm:left-4 lg:left-8 lg:top-[18rem] z-40  w-[90vw] bg-[white] shadow-lg shadow-slate-600 pb-4 rounded-xl ${
              Box8 === true ? "hidden" : "inline-block"
            }`}
          >
            <RxCross2
              className='absolute top-4 right-4 text-[1.5rem] z-50'
              onClick={() => setBox8(true)}
            />
            <Navratre />
          </div>
          {/*  */}

          <div
            className=' rounded-xl h-[12rem] w-[20rem] mx-auto my-2 cursor-pointer relative'
            onMouseEnter={() => blackout7Fun()}
            onMouseLeave={() => blackoutClose7Fun()}
            onClick={() => setBox1(false)}
          >
            <div
              className={`rounded-xl w-[20rem] bg-[#0000006d] absolute text-white text-center items-center justify-center text-[2rem] duration-1000 ${
                hovering7 === true ? "blackoutClass" : "hidden"
              }`}
            >
              General
            </div>
            <img
              src={general_img}
              className=' rounded-xl h-[12rem] w-[20rem]'
              alt={general_img}
            />
          </div>

          <div
            className=' rounded-xl h-[12rem] w-[20rem] mx-auto my-2 cursor-pointer relative'
            onMouseEnter={() => blackoutFun()}
            onMouseLeave={() => blackoutCloseFun()}
            onClick={() => setBox2(false)}
          >
            <div
              className={`rounded-xl w-[20rem] bg-[#0000006d] absolute text-white text-center items-center justify-center text-[2rem] duration-1000 ${
                hovering === true ? "blackoutClass" : "hidden"
              }`}
            >
              Aerial View
            </div>
            <img
              src={aerialView}
              className=' rounded-xl h-[12rem] w-[20rem]'
              alt={aerialView}
            />
          </div>

          <div
            className=' rounded-xl h-[12rem] w-[20rem] mx-auto my-2 cursor-pointer'
            onMouseEnter={() => blackout1Fun()}
            onMouseLeave={() => blackoutClose1Fun()}
            onClick={() => setBox3(false)}
          >
            <div>
              <div
                className={`rounded-xl w-[20rem] bg-[#0000006d] absolute text-white text-center items-center justify-center text-[2rem] duration-1000 ${
                  hovering1 === true ? "blackoutClass" : "hidden"
                }`}
              >
                Diwali
              </div>
              <img
                src={diwali_img}
                className=' rounded-xl h-[12rem] w-[20rem]'
                alt={diwali_img}
              />
            </div>
          </div>

          <div
            className=' rounded-xl h-[12rem] w-[20rem] mx-auto my-2 cursor-pointer'
            onMouseEnter={() => blackout2Fun()}
            onMouseLeave={() => blackoutClose2Fun()}
            onClick={() => setBox4(false)}
          >
            <div>
              <div
                className={`rounded-xl w-[20rem] bg-[#0000006d] absolute text-white text-center items-center justify-center text-[2rem] duration-1000 ${
                  hovering2 === true ? "blackoutClass" : "hidden"
                }`}
              >
                Dussehra
              </div>
              <img
                src={dussera_img}
                className=' rounded-xl h-[12rem] w-[20rem]'
                alt={dussera_img}
              />
            </div>
          </div>

          <div
            className=' rounded-xl h-[12rem] w-[20rem] mx-auto my-2 cursor-pointer'
            onMouseEnter={() => blackout3Fun()}
            onMouseLeave={() => blackoutClose3Fun()}
            onClick={() => setBox5(false)}
          >
            <div>
              <div
                className={`rounded-xl w-[20rem] bg-[#0000006d] absolute text-white text-center items-center justify-center text-[2rem] duration-1000 ${
                  hovering3 === true ? "blackoutClass" : "hidden"
                }`}
              >
                Karwa Chauth
              </div>
              <img
                src={karwa_img}
                className=' rounded-xl h-[12rem] w-[20rem]'
                alt={karwa_img}
              />
            </div>
          </div>

          <div
            className=' rounded-xl h-[12rem] w-[20rem] mx-auto my-2 cursor-pointer'
            onMouseEnter={() => blackout4Fun()}
            onMouseLeave={() => blackoutClose4Fun()}
            onClick={() => setBox6(false)}
          >
            <div>
              <div
                className={`rounded-xl w-[20rem] bg-[#0000006d] absolute text-white text-center items-center justify-center text-[2rem] duration-1000 ${
                  hovering4 === true ? "blackoutClass" : "hidden"
                }`}
              >
                Lohri
              </div>
              <img
                src={lohri_img}
                className=' rounded-xl h-[12rem] w-[20rem]'
                alt={lohri_img}
              />
            </div>
          </div>

          <div
            className=' rounded-xl h-[12rem] w-[20rem] mx-auto my-2 cursor-pointer'
            onMouseEnter={() => blackout5Fun()}
            onMouseLeave={() => blackoutClose5Fun()}
            onClick={() => setBox7(false)}
          >
            <div>
              <div
                className={`rounded-xl w-[20rem] bg-[#0000006d] absolute text-white text-center items-center justify-center text-[2rem] duration-1000 ${
                  hovering5 === true ? "blackoutClass" : "hidden"
                }`}
              >
                Republic Day
              </div>
              <img
                src={republic_img}
                className=' rounded-xl h-[12rem] w-[20rem]'
                alt={republic_img}
              />
            </div>
          </div>

          <div
            className=' rounded-xl h-[12rem] w-[20rem] mx-auto my-2 cursor-pointer'
            onMouseEnter={() => blackout6Fun()}
            onMouseLeave={() => blackoutClose6Fun()}
            onClick={() => setBox8(false)}
          >
            <div>
              <div
                className={`rounded-xl w-[20rem] bg-[#0000006d] absolute text-white text-center items-center justify-center text-[2rem] duration-1000 ${
                  hovering6 === true ? "blackoutClass" : "hidden"
                }`}
              >
                Navratre
              </div>
              <img
                src={navratre_img}
                className=' rounded-xl h-[12rem] w-[20rem]'
                alt={navratre_img}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery;
